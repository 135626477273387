import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LoggerService } from '@wdpr/ra-angular-logger';
import { SeoService } from '@wdpr/ra-angular-seo-metadata';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import get from 'lodash-es/get';

import { LoadingService } from '@finder/shared/services/loading-service/loading-service.service';
import { CAMPAIGN_CONSTANTS, CONFIG_CONSTANTS } from '@finder/shared/constants/app.constants';
import { FinderApiConnectorService } from '@finder/shared/services/finder-api-connector/finder-api-connector.service';
import { FinderThemeService } from '@finder/shared/services/theme-service/finder-theme.service';
import { FinderAnalyticsService } from '@finder/shared/services/finder-analytics/finder-analytics.service';
import { InterstitialService } from '@finder/shared/services/interstitial-service/interstitial-service.service';
import { SoloService } from '@finder/shared/services/solo-service/solo.service';
import { WindowRef } from '@finder/shared/services/window-ref/window-ref.service';
import { FinderPageKeyService } from '@finder/shared/services/finder-page-key/finder-page-key.service';
import { AuthenticationService } from '@finder/shared/services/authentication/authentication.service';
import { ConfigService } from '@finder/core/config.service';
import { FinderSeoService } from '@finder/shared/services/finder-seo/finder-seo.service';
import { TravelAgentService } from '@finder/shared/services/travel-agent/travel-agent.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { STATUS_CODES } from '../constants/response-code.constants';
import { Meta } from '@angular/platform-browser';

@Injectable()
export class DynamicResolver implements Resolve<any> {

    constructor(
        private loadingService: LoadingService,
        private finderApi: FinderApiConnectorService,
        private themeService: FinderThemeService,
        private finderAnalyticsService: FinderAnalyticsService,
        private soloService: SoloService,
        private windowRef: WindowRef,
        private logger: LoggerService,
        private authenticationService: AuthenticationService,
        private configService: ConfigService,
        private finderPageKeyService: FinderPageKeyService,
        private seoService: SeoService,
        private finderSeoService: FinderSeoService,
        private travelAgentService: TravelAgentService,
        private localStorageService: LocalStorageService,
        private interstitialService: InterstitialService,
        private metaTagService: Meta,
        @Inject(DOCUMENT) private document: Document,
    ) { }

    resolve(route: ActivatedRouteSnapshot) {
        // When coming from listing page we need to make sure we scroll top
        // and we set loading state into false
        this.document.documentElement.scrollTop = 0;
        // Reset of Error state to avoid getting Error message stuck after back button pressed GIT-31197
        this.loadingService.setErrorState(null);
        this.loadingService.setLoadingState(false);

        // If we try to go to a trade page but we don't have the session cookie, show an error.
        const tradeError = this.travelAgentService.showErrorIfCookieIsMissing();
        if (tradeError) {
            return;
        }

        // Spliting on a character that doesn't exist returns an array of the value
        const [slugId] = route.paramMap.get('id').split('?');

        /**
         * Use a promise to resolve the route and never reject
         * the error so we can stay on the same URI
         */
        return new Promise((resolve, reject) => {
            this.finderApi.getDetailsBySlugId(slugId)
                .pipe(
                    catchError(async error => {
                        // Get the status code if available, or just use 400 as default
                        const status = get(error, 'status', STATUS_CODES.BAD_REQUEST);
                        const isMyIdGated = get(error, 'error.myIdGated', false);

                        switch (status) {
                            case STATUS_CODES.UNAUTHORIZED:
                                // Check if this page is gated by myid
                                if (isMyIdGated) {
                                    return this.myIdLoginRedirect();
                                }

                                // Redirect to login
                                return this.loginRedirect(error.error.webLink);
                            case STATUS_CODES.FORBIDDEN:
                                // Check if this page is gated by myid
                                if (isMyIdGated) {
                                    this.myIdGatedMessage(error);
                                } else {
                                    // Redirect to passholder affiliation page
                                    return this.passholderAffiliationRedirect();
                                }

                                break;
                            case STATUS_CODES.NOT_FOUND:
                                /**
                                 * https://myjira.disney.com/browse/GIT-54856
                                 * add the correct headers so prerender can let crawlers know it's a bad url
                                 */
                                this.updateMetaTags(status);
                                break;
                        }

                        // Analytics for error page
                        await this.finderAnalyticsService.updateErrorPage(status);
                        await this.finderAnalyticsService.updateStoreType();
                        this.finderAnalyticsService.updateAnalyticsLoader();

                        this.loadingService.setErrorState(error);
                        this.loadingService.setLoadingState(true);

                        /**
                         * Is needed to resolve the promise so the url redirection will be carried out
                         * when we want to show our error messaging page.
                         * This was changed to null so the page won't try to render if it receives
                         * an error object from a request that failed.
                         */
                        resolve(null);

                        // This doesn't really do anything other an stop the error from a
                        // method that wants an Observable returned to it
                        return EMPTY;
                    })
                ).subscribe(data => {
                    // Change theme
                    this.changeTheme(data);

                    // Set fallback value for pagekey service
                    this.finderPageKeyService.setFallbackPageKey(data.webLink);

                    // Check for metadata in response
                    // If we have it, cool - if not, explicitly load the seo data
                    if (data.meta) {
                        this.seoService.setSeoData(data.meta);
                    } else {
                        this.finderSeoService.getPageKeyAndSetSeo();
                    }

                    // Add a class to hook CSS onto to remove elements of the global nav and
                    // footer, per https://myjira.disney.com/browse/GIT-4439
                    const useMinimal = get(data, 'useMinimal', false);

                    if (useMinimal) {
                        this.themeService.addClassToBody('minimal-syndicated');
                    }

                    if (data.interstitialModalData) {
                        this.interstitialService.setInterstitialData(data.interstitialModalData);
                    }

                    this.loadingService.setLoadingState(true);

                    resolve(data);
                });
        });

    }

    private changeTheme(data) {
        // Get the theme - there should ALWAYS be a theme property
        const theme = get(data, CAMPAIGN_CONSTANTS.themeKey) || get(data, 'theme', {}) ;

        // Set the theme
        this.themeService.setCurrentTheme(theme.finder || theme);

        // Check if we need to change the nav theme
        if (theme.navigator) {
            this.themeService.addClassToBody(theme.navigator);
        }

        // Check if this is an overview
        const isOverview = get(data, CAMPAIGN_CONSTANTS.isOverview, false);
        if (isOverview) {
            this.themeService.addClassToBody('overview');
        }

        // Add body class(es)
        if (data.bodyCssClass) {
            this.themeService.addClassToBody(data.bodyCssClass);
        }

        // Body classes from CMS
        if (theme.bodyCssClasses) {
            this.themeService.addClassToBody(theme.bodyCssClasses);
        }
    }

    private myIdLoginRedirect() {
        // Redirect to myid login
        this.windowRef.nativeWindow.location.href = this.soloService.getLoginRedirectUrl(
            this.windowRef.nativeWindow.location.href
        );

        return EMPTY;
    }

    private myIdGatedMessage(error) {
        const message = `THIS PAGE IS GATED: The current MyId user doesn't have access to this page`;
        error.error.myIdGatedMessage = message;
        this.logger.warn(message);
    }

    private loginRedirect(webLink) {
        const location = this.windowRef.nativeWindow.location;
        /**
         * If the guest clicks cancel we should return them to the current page
         * On successful login we should navigate to the requested page
         */
        const options: any = {
            // This will take the guest to the selected page
            returnUrl: webLink,
            // This one needs to be encoded. It's not touched later
            cancelUrl: encodeURIComponent(location.pathname)
        };

        /**
         * This will store cancelUrl so that the affiliations page can return to a
         * non-gated page. Store this unencoded
         */
        this.localStorageService.set('claimReferrer', location.pathname);

        this.authenticationService.redirectToLogin(options);

        return EMPTY;
    }

    private passholderAffiliationRedirect() {
        const siteId = this.configService.getValue(CONFIG_CONSTANTS.siteIdKey);
        this.windowRef.nativeWindow.location.href = this.configService.getValue(`${CONFIG_CONSTANTS.passholderLinkRoute}.${siteId}`);

        return EMPTY;
    }

    private updateMetaTags(statusCode) {
        const tags = [];

        switch (statusCode) {
            case STATUS_CODES.NOT_FOUND:
                // we should look for an existing robots meta tag and remove it
                this.metaTagService.removeTag(`name='robots'`);

                // create tags to add to the page
                tags.push({
                    name: 'prerender-status-code',
                    content: statusCode
                }, {
                    name: 'robots',
                    content: 'noindex, nofollow'
                }, {
                    name: 'description',
                    content: 'The requested page could not be found.'
                });

                break;
        }

        // add the tags
        this.metaTagService.addTags(tags);
    }
}
